import * as React from "react";
import backgroundImage from "../images/studios/DSC_6452.jpg";
import Landing from "../components/landing";

export default function IndexPage() {
  return (
    <Landing
      title="Game + Music > Game"
      subtitle="Up to -50% for Indies"
      currentPage="/for-indies"
      backgroundImage={backgroundImage}
    />
  );
}
